import { CruiseCardDataB2cExpeditionOffer } from '@content/queries/fragments/contentApi/cruiseCardParts';
import { OfferPage } from '@content/models/offerPage';

import { notEmpty } from '../typeGuards/shared';

export const getOfferTagForVoyage = (
  offer: OfferPage,
  voyageId: string,
  voyageDepartureDates: string[] = []
) => {
  // Dates are of format 2024-01-25T00:00:00,
  // where the time part is optional.
  // Taking the first 10 corresponds to 2024-01-25.
  const mappedDepartures = voyageDepartureDates.map((x) => x.slice(0, 10));
  const matchesDeparture = (dates: string[]) =>
    dates.length === 0 ||
    dates.map((d) => d.slice(0, 10)).some((d) => mappedDepartures.includes(d));

  const specificTag = offer.tags?.find(
    (t) =>
      t.applicableVoyageIds.includes(voyageId) &&
      t.applicableDates &&
      matchesDeparture(t.applicableDates)
  );
  const genericTag = offer.tags?.find(
    (t) =>
      t.applicableVoyageIds.length === 0 &&
      t.applicableDates &&
      matchesDeparture(t.applicableDates)
  );

  return specificTag?.name || genericTag?.name || null;
};

export const getPrioritizedOfferTagsForVoyage = (
  offers: Pick<CruiseCardDataB2cExpeditionOffer, 'tags'>[],
  voyageId: string
) => {
  const specificTags = offers.map((offer) =>
    offer.tags.find((tag) => tag.applicableVoyageIds.includes(voyageId))
  );

  const genericTags = offers.map((offer) =>
    offer.tags.find((tag) => tag.applicableVoyageIds.length === 0)
  );

  const mergedTags = specificTags.map(
    (specificTag, i) => specificTag || genericTags[i] || null
  );

  return mergedTags
    .filter(notEmpty)
    .filter((t) => t.name?.trim() !== '')
    .sort((a, b) => (b.isFeaturedOffer ? 1 : 0) - (a.isFeaturedOffer ? 1 : 0))
    .map((tag) => ({
      name: tag.name as string,
      isFeaturedOffer: tag.isFeaturedOffer
    }));
};
