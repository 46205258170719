import { useEffect, useState } from 'react';
import Link from 'next/link';
import { Button } from '@hurtigruten/design-system-components';

import { useMediaQuery } from '@hooks';
import { CruiseCard } from '@molecules';
import { breakpoints, trackEvent } from '@utils';
import {
  CruiseCard as CruiseCardViewModel,
  toCruiseCardInfo
} from '@content/models/cruiseCard';
import { createViewVoyageListEvent } from '@src/utils/analytics/eventBuilder';
import { returnItemFromCruiseCardInfoModel } from '@src/utils/analytics/convertData';

const LIMIT = 3;

const AssociatedVoyages = ({
  isDark,
  voyages,
  showMoreLabel,
  showMoreHref,
  isDisplayMoreData = false,
  uploadMoreLabel,
  isDisplayNavigationButton = false,
  limit = LIMIT,
  recordEvents = true
}: {
  isDark?: boolean;
  voyages: CruiseCardViewModel[];
  showMoreLabel?: string;
  showMoreHref?: string;
  isDisplayMoreData?: boolean;
  uploadMoreLabel?: string;
  isDisplayNavigationButton?: boolean;
  limit?: number;
  recordEvents?: boolean;
}) => {
  const [voyagesList, setVoyagesList] = useState(voyages.slice(0, limit));
  const [index, setIndex] = useState(limit);
  const [isShowMore, setiIShowMore] = useState(voyages?.length > limit);
  const isLaptop = useMediaQuery(breakpoints.laptop);
  const isButtonDisplayed =
    (voyages.length > limit && showMoreHref) || isDisplayNavigationButton;
  const eventId = 'associated-voyages';
  const eventName = 'associated voyages';

  const loadMore = () => {
    const updatedIndex = index + limit;
    const hasMoreData = updatedIndex < voyages?.length;
    const updatedList = voyagesList.concat(voyages?.slice(index, updatedIndex));
    setIndex(updatedIndex);
    setVoyagesList(updatedList);
    setiIShowMore(hasMoreData);
    if (recordEvents) {
      trackEvent(
        createViewVoyageListEvent(
          eventId,
          eventName,
          updatedList.map((v) => {
            const cruise = toCruiseCardInfo(v);
            return returnItemFromCruiseCardInfoModel(cruise);
          })
        )
      );
    }
  };

  /* TODO: Add 'id' and 'name' to events. Where voyages are part of a 
  CF block that might have an id then pass that to this event instead
  of the default 'associated-voyages'. */
  useEffect(() => {
    if (recordEvents) {
      trackEvent(
        createViewVoyageListEvent(
          eventId,
          eventName,
          voyagesList.map((v) => {
            const cruise = toCruiseCardInfo(v);
            return returnItemFromCruiseCardInfoModel(cruise);
          })
        )
      );
    }
  }, []);

  if (!voyages.length) {
    return null;
  }

  return (
    <div className="w-full">
      <div
        id="associated-voyages"
        role="region"
        aria-live="polite"
        className="grid gap-6 grid-cols-voyages-sm laptop:grid-cols-3"
      >
        {voyagesList.map((voyageData) => {
          const cruise = toCruiseCardInfo(voyageData);

          return (
            <div
              key={voyageData.id}
              className="flex"
              data-testid="associated-voyage-card"
            >
              <CruiseCard
                isInfoSectionIndented
                isRoundedImage={false}
                cruise={cruise}
                size={isLaptop ? 'medium' : 'small'}
              />
            </div>
          );
        })}
      </div>

      {isButtonDisplayed && (
        <div className="flex justify-center w-full mt-8 laptop:mt-12">
          <Link href={showMoreHref ?? ''} passHref legacyBehavior>
            <Button
              testId="associated-voyages-redirect"
              appearance={
                isDark && isDark !== undefined ? 'secondary' : 'primary'
              }
              text={showMoreLabel}
              fillMobileWidth={false}
            />
          </Link>
        </div>
      )}

      {isShowMore && isDisplayMoreData && (
        <div className="flex justify-center w-full mt-8 laptop:mt-12">
          <Button
            aria-controls="associated-voyages"
            fillMobileWidth={false}
            testId="associated-voyages-load-more"
            onClick={loadMore}
            icon="arrow-down"
            appearance={
              isDark && isDark !== undefined ? 'secondary' : 'primary'
            }
            text={uploadMoreLabel}
          />
        </div>
      )}
    </div>
  );
};

export default AssociatedVoyages;
